import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Menu/Sidebar";
import "../../assets/css/form.css";
import ADD_DETAIL_HEADER from "../../components/Add_Detail_Header";
import { AccountContext } from "../../routes/AccountContext";
import {
  MainCategory,
  Release,
  Mobility,
  Stretch,
  Activation,
  Power,
  Strength,
  Correctives,
  Coordination,
  Core,
  Speed,
  Perinatal,
} from "../../assets/constants";

function ExerciseEdit() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [tips, setTips] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const { user } = useContext(AccountContext);
  let params = useParams();
  const navigate = useNavigate();

  const handleAddDefaultTag = (tag) => {
    setSelectedTags([...selectedTags, tag]);
  };

  const handleRemoveTag = (tag) => {
    setSelectedTags(selectedTags.filter((t) => t !== tag));
  };

  const handleMainCategoryChange = (category) => {
    setSelectedMainCategory(category);
    setSelectedTags([]);
  };

  const handleRemoveMainCategoryTag = () => {
    setSelectedMainCategory(null);
    setSelectedTags([]);
  };

  useEffect(() => {
    const getExercise = async () => {
      try {
        const route = params.exerciseId;
        const response = await fetch(
          `${process.env.REACT_APP_API_MAIN_URL}exercises/exercise/${route}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const jsonData = await response.json();
        setName(jsonData.name);
        setDescription(jsonData.description);
        setVideoUrl(`https://www.youtube.com/watch?v=${jsonData.videourl}`);
        setTips(jsonData.tips);
        setEquipment(jsonData.equipment);
        setSelectedMainCategory(jsonData.targets[0])
        setSelectedTags(jsonData.targets.slice(1));
      } catch (err) {
        console.log(err.message);
      }
    };

    getExercise();
  }, []);

  const updateExercise = async () => {
    const route = params.exerciseId;

    if (videoUrl.split("watch?v=").length > 1) {
      try {
        fetch(`${process.env.REACT_APP_API_MAIN_URL}exercises/${route}`, {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({
            name,
            description,
            videoUrl: videoUrl.split("watch?v=")[1],
            tips,
            equipment,
            targets: [selectedMainCategory, ...selectedTags],
          }),
        }).then((response) => {
          if (response.status === 200) {
            alert("The exercise is updated");
            navigate("/exercises");
          } else {
            alert("There went something wrong");
          }
        });
      } catch (err) {
        alert("There went something wrong");
        console.log(err.message);
      }
    } else {
      alert("Please use a valid YouTube link.");
    }
  };

  return (
    <div className="layout">
      <Sidebar selected="Exercises" />
      <div className="layout__content">
        <div className="layout__content-main">
          <ADD_DETAIL_HEADER title={"Let's edit this exercise"} />

          <form
            onSubmit={(e) => {
              updateExercise();
              e.preventDefault();
            }}
          >
            <div>
              <div>
                <h4>Name exercise</h4>
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div>
                <h4>Description</h4>
                <textarea
                  rows="5"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>
              <div>
                <h4>Video URL</h4>
                <input
                  type="url"
                  placeholder="Url"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  required
                />
              </div>
              <div>
                <h4>Tips</h4>
                <input
                  type="text"
                  placeholder="Split by using a ','"
                  value={tips}
                  onChange={(e) => setTips(e.target.value.split(","))}
                />
              </div>
              <div>
                <h4>Equipment needed</h4>
                <input
                  type="text"
                  placeholder="Split by using a ','"
                  value={equipment}
                  onChange={(e) => setEquipment(e.target.value.split(","))}
                />
              </div>
              <div>
                <h4>Targets</h4>
                <div>
                  <h6 className="tag-title">Main Category</h6>
                  <div className="tagBox">
                    {MainCategory.sort().map((category) => (
                      <button
                        className={`tag main-tag ${
                          selectedMainCategory === category && "selected"
                        }`}
                        key={category}
                        type="button"
                        onClick={() => handleMainCategoryChange(category)}
                      >
                        {category}
                      </button>
                    ))}
                  </div>
                </div>
                <div>
                  {selectedMainCategory !== null && (
                    <h6 className="tag-title">Sub Category</h6>
                  )}
                  <div className="tagBox">
                    {getTagsForCategory(selectedMainCategory)
                      .filter((tag) => !selectedTags.includes(tag))
                      .sort()
		      .map((tag) => (
                        <button
                          className="tag"
                          key={tag}
                          type="button"
                          onClick={() => handleAddDefaultTag(tag)}
                        >
                          {tag}
                        </button>
                      ))}
                  </div>
                </div>
              </div>
              <div>
                <div className="inputWithTags tagBox">
                  {selectedMainCategory !== null && (
                    <button
                      className="tag main-tag"
                      onClick={() =>
                        handleRemoveMainCategoryTag(selectedMainCategory)
                      }
                    >
                      {selectedMainCategory} &nbsp; &times;
                    </button>
                  )}
                  {selectedTags.map((tag) => (
                    <button
                      className="tag"
                      key={tag}
                      type="button"
                      onClick={() => handleRemoveTag(tag)}
                    >
                      {tag} &nbsp; &times;
                    </button>
                  ))}
                </div>
              </div>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ExerciseEdit;


function getTagsForCategory(category) {
  switch (category) {
    case "Release":
      return Release;
    case "Mobility":
      return Mobility;
    case "Stretch":
      return Stretch;
    case "Activation":
      return Activation;
    case "Power":
      return Power;
    case "Strength":
      return Strength;
    case "Correctives":
      return Correctives;
    case "Core":
      return Core;
    case "Coordination":
      return Coordination;
    case "Speed":
      return Speed;
    case "Perinatal":
      return Perinatal;
    default:
      return [];
  }
}
