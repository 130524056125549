import React, { useState } from 'react';
import './SearchBar.css';
import { FaSearch } from 'react-icons/fa';
import {
  Release,
  Mobility,
  Stretch,
  Activation,
  Power,
  Strength,
  Correctives,
  Core,
  Coordination,
  Speed,
  Perinatal,
} from "../../assets/constants";

const SearchBar = ({
  searchQuery,
  setSearchQuery,
  placeholder,
  btnName,
  btnOnClick,
  mainTargets,
  selectedMainTarget,
  setSelectedMainTarget,
  selectedSubTargets,
  setSelectedSubTargets
}) => {

  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const toggleTarget = (target) => {
    if (selectedMainTarget === target) {
      setSelectedMainTarget("");
    } else {
      setSelectedMainTarget(target);
    }
  };

  const toggleSubTarget = (target) => {
    if (selectedSubTargets.includes(target)) {
      setSelectedSubTargets(selectedSubTargets.filter((t) => t !== target));
    } else {
      setSelectedSubTargets([...selectedSubTargets, target]);
    }
  };

  return (
    <div className={btnName ? 'searchbarWithButton' : ''}>
      <div className='filterOptions'>
      {mainTargets && (
          <div className='optionBox'>
            <button
              onClick={() => setShowFilterOptions(!showFilterOptions)}
              className={
                showFilterOptions
                  ? 'selected'
                  : ''
              }
            >
              Filter
            </button>
            {showFilterOptions &&
              mainTargets.map((target) => (
                <button
                  key={target}
                  className={
                    selectedMainTarget.includes(target)
                      ? 'selected option'
                      : 'option'
                  }
                  onClick={() => toggleTarget(target)}
                >
                  {target}
                </button>
              ))}

{showFilterOptions && selectedMainTarget !== "" &&
      getTagsForCategory(selectedMainTarget)
              .map((target) => (
                <button
                  key={target}
                  className={
                    selectedSubTargets.includes(target)
                      ? 'selected-sub option'
                      : 'option-sub option'
                  }
                  onClick={() => toggleSubTarget(target)}
                >
                  {target}
                </button>
              ))}
          </div>
        )}
      </div>
      <div className='searchbar'>
        <div className='searchbar_Logo'>
          <FaSearch size={'25px'} />
        </div>
        <div className='searchbar_Search'>
          <form action='/' method='get'>
            <input
              value={searchQuery}
              onInput={(e) => setSearchQuery(e.target.value)}
              type='text'
              id='header-search'
              placeholder={placeholder}
              name='s'
            />
          </form>
        </div>
      </div>
      {btnName && (
        <button
          onClick={() => {
            btnOnClick();
          }}
        >
          {btnName}
        </button>
      )}
    </div>
  );
};
const filterPosts = (posts, query, type, selectedTargets) => {
  if (!query && (!selectedTargets || selectedTargets.length === 0)) {
    return posts;
  }

  // Define a helper function to convert a string to lowercase
  const toLowerCase = (str) => (str ? str.toLowerCase() : '');

  if (type === 'exercise') {
    return posts.filter((post) => {
      const postName = toLowerCase(post.name);
      const matchesQuery = postName.includes(query.toLowerCase());
      
      if (selectedTargets.length === 0) {
        return matchesQuery;
      }

      return (
        matchesQuery &&
        post.targets &&
        post.targets.some((target) => selectedTargets.includes(target))
      );
    });
  }

  if (type === 'sidebarItem') {
    return posts.filter((post) => {
      const postName = toLowerCase(
        post.exercise
          ? post.exercise.name
          : post.circuit_auxilary.name
      );
      return postName.includes(query.toLowerCase());
    });
  }
  if (type === 'member') {
    return posts.filter((post) => {
      const postName = toLowerCase(
        post.firstname + ' ' + post.lastname
      );
      return postName.includes(query.toLowerCase());
    });
  }
  return posts;
};

export { SearchBar, filterPosts };

function getTagsForCategory(category) {
  switch (category) {
    case "Release":
      return Release;
    case "Mobility":
      return Mobility;
    case "Stretch":
      return Stretch;
    case "Activation":
      return Activation;
    case "Power":
      return Power;
    case "Strength":
      return Strength;
    case "Correctives":
      return Correctives;
    case 'Core':
      return Core;
    case "Coordination":
      return Coordination;
    case "Speed":
      return Speed;
    case "Perinatal":
      return Perinatal;
    default:
      return [];
  }
}
